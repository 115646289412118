<route>
{
  "meta": {
    "auth": "vendorList"
  }
}
</route>

<template>
  <div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">商家名称</span>
          <el-input
            class="searchInput"
            @keyup.enter.native="getList"
            v-model.trim="searchValue.searchByName"
            placeholder="商家名称"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">商家编号</span>
          <el-input
            class="searchInput"
            @keyup.enter.native="getList"
            oninput="value=value.replace(/[^0-9]/g,'')"
            v-model="searchValue.businessId"
            placeholder="商家ID"
            clearable
          >
          </el-input>
        </el-col>

        <el-col :span="8" v-if="$store.getters['user/isAllArea']">
          <span class="searchText"> 地区</span>
          <el-select v-model="searchValue.areaId" placeholder="请选择城市">
            <el-option label="全部" :value="-1"></el-option>
            <el-option
              v-for="item in $store.getters['user/userAreaInfoListWithoutGlobal']"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">商家评分</span>
          <el-select v-model="searchValue.refundCheckScore" placeholder="请选择状态等级">
            <el-option label="全部" value=""></el-option>
            <el-option label="A+" value="A+"></el-option>
            <el-option label="A" value="A"></el-option>
            <el-option label="B" value="B"></el-option>
            <el-option label="C" value="C"></el-option>
            <el-option label="D" value="D"></el-option>
          </el-select>
        </el-col>
        <el-col :span="8" :offset="8">
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button></el-col
        ></el-row
      >
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>商家列表</span>
        <el-button @click="vendor('')" type="success" style="float: right">添加新商家</el-button>
        <el-button @click="vendorCreateExport.show = true" style="float: right; margin-right: 15px"
          >商家创建信息导出</el-button
        >
      </div>
      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="id" label="商家ID"> </el-table-column>
        <el-table-column align="center" prop="id" label="商家LOGO" width="90">
          <template slot-scope="scope">
            <div class="product-info">
              <img class="avatar" :src="scope.row.businessLogo" />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商家名称" align="center">
          <template slot-scope="scope">
            <div class="product-info">
              <span class="title"> {{ scope.row.businessTitle }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column align="center" label="地区">
          <template slot-scope="scope">
            <div class="product-info">
              <span class="title"> {{ scope.row.areaIds | formatManyAreas }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="storeCount" label="门店数量"> </el-table-column>
        <el-table-column align="center" prop="businessStaffCount" label="人员数量"> </el-table-column>

        <el-table-column align="center">
          <template slot-scope="scope" slot="header">
            <el-tooltip effect="dark" placement="top">
              <div slot="content">
                取该商家所有商品退款核销比的平均值来评分<br />
                A+：退款核销比在0-0.10<br />
                A：退款核销比在0.11-0.30 <br />
                B：退款核销比在0.31-0.50<br />
                C：退款核销比在0.51-1 <br />
                D：退款核销比大于1（包含数据操作总量少于5% ） <br />
                ⚠️：最近七天创建的商家不参与评分【显示字段‘-’】
              </div>
              <span
                >商家评分
                <i style="color: blue; font-size: 14px" class="questionIcon el-icon-question" slot="reference"></i
              ></span>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <div style="display: flex; justify-content: center">
              <div
                class="circle"
                :class="`circle-color${scope.row.refundCheckScore == 'A+' ? 'superA' : scope.row.refundCheckScore}`"
              >
                {{ scope.row.refundCheckScore | filterRefundAndWriteoffRadio }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="createTime" label="创建时间">
          <template slot-scope="scope">
            <span class="info"> {{ scope.row.createTime | formatTime }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="230">
          <template slot-scope="scope">
            <el-dropdown @command="handleManage($event, scope.row)" style="margin-right: 5px">
              <el-button type="text" size="small">
                商家管理
                <i class="el-icon-arrow-down"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :disabled="scope.row.disabled" command="businessInfo">商家信息</el-dropdown-item>
                <el-dropdown-item :disabled="scope.row.disabled" command="store">门店管理</el-dropdown-item>
                <el-dropdown-item :disabled="scope.row.disabled" v-if="!scope.row.businessModel" command="account"
                  >二级商户号</el-dropdown-item
                >
                <el-dropdown-item :disabled="scope.row.disabled" command="reservation">预约规则</el-dropdown-item>
                <el-dropdown-item :disabled="scope.row.disabled" command="role">门店角色</el-dropdown-item>
                <el-dropdown-item :disabled="scope.row.disabled" command="person">人员管理</el-dropdown-item>
                <el-dropdown-item :disabled="scope.row.disabled" command="reservationRecord">预约记录</el-dropdown-item>
                <el-dropdown-item :disabled="scope.row.disabled" v-if="scope.row.businessModel" command="collection"
                  >收款人</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown @command="handleProductManage($event, scope.row)" style="margin-right: 5px">
              <el-button type="text" size="small">
                商品管理
                <i class="el-icon-arrow-down"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="standard">普通商品</el-dropdown-item>
                <el-dropdown-item command="partner">导码商品</el-dropdown-item>
                <el-dropdown-item command="codeless">无码商品</el-dropdown-item>
                <el-dropdown-item command="seckill">限时商品</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <el-button @click="vendor(scope, true)" type="text" size="small">查看</el-button>
            <el-button type="text" @click="showDelete(scope)" size="small" class="deleteBtnText">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 删除组件 -->
    <bm-deleteItem
      url="boom-center-product-service/sysAdmin/business/"
      :timestamp="timestamp"
      @successDelete="successDelete"
    ></bm-deleteItem>

    <el-dialog
      :close-on-click-modal="false"
      title="导出预约信息"
      :visible.sync="download.show"
      append-to-body
      width="30%"
    >
      <div style="margin-top: 10px">
        <span style="display: inline-block; width: 60px"> 时间</span>
        <el-date-picker
          value-format="timestamp"
          v-model="download.time"
          type="datetimerange"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="download.show = false">取 消</el-button>
        <el-button type="primary" @click="confirmDownLoadExcel">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="vendorCreateExport.show" title="商家创建信息导出" width="500px">
      <el-date-picker
        v-model="vendorCreateExport.date"
        type="datetimerange"
        :picker-options="vendorCreateExport.pickerOptions"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        align="right"
      >
      </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="vendorCreateExport.show = false">取 消</el-button>
        <el-button
          type="primary"
          @click="businessCreateInfoExport"
          :loading="vendorCreateExport.acting"
          :disabled="vendorCreateExport.acting"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { pagination } from '@/mixin/pagination'
import { deleteRowItem } from '@/mixin/deleteRowItem'

export default {
  name: 'businessList',
  mixins: [pagination, deleteRowItem],
  data() {
    return {
      download: {
        show: false,
        time: [],
        url: ''
      },
      list: [],
      timestamp: 0,
      searchValue: {
        businessId: '',
        searchByName: '',
        businessDistrictId: '',
        refundCheckScore: '',
        areaId: ''
      },
      vendorCreateExport: {
        show: false,
        date: '',
        acting: false,
        fileUrl: '',
        pickerOptions: {
          shortcuts: [
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              }
            },
            {
              text: '最近一个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              }
            },
            {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                picker.$emit('pick', [start, end])
              }
            }
          ]
        }
      }
    }
  },
  created() {
    if (JSON.parse(sessionStorage.currentAreaId) !== -1) {
      this.searchValue.areaId = JSON.parse(sessionStorage.currentAreaId)
    }
    console.log(this.$route.query.id)
    if (this.$route.query.id) {
      this.searchValue.businessId = this.$route.query.id
      const that = this
      setTimeout(() => {
        that.getList()
      }, 500)
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$route.query.id) {
        vm.searchValue.businessId = vm.$route.query.id
      }
      const that = this
      setTimeout(() => {
        vm.getList(true)
      }, 500)
    })
  },

  deactivated() {
    this.searchValue.businessId = ''
  },
  methods: {
    ...mapMutations({ updateCurrentBusiness: 'business/updateCurrentBusiness' }),
    renderHeader(h, colum) {
      return h('div', [
        h(
          'span',
          {
            style: `cursor: pointer;`,
            on: {
              click: this.seeDetail
            }
          },
          '查看详情'
        )
      ])
    },
    handleManage(command, val) {
      this.updateCurrentBusiness(val)
      this.$store.commit('vendor/params/updateParams', { key: 'vendorId', value: val.id })
      this.$store.commit('vendor/params/updateParams', { key: 'vendorName', value: val.businessTitle })
      this.$store.commit('vendor/params/updateParams', { key: 'businessModel', value: val.businessModel })
      if (command === 'businessInfo') {
        this.$store.commit('vendor/params/updateParams', { key: 'vendorCheck', value: false })
        this.$router.push(`/vendor/vendorMange/list/vendorEdit`)
      } else if (command == 'reservationRecord') {
        this.download = {
          show: true,
          time: [],
          url: '/boom-center-statistics-service/sysAdmin/exportExl/bookRecord',
          id: val.id
        }
      } else {
        this.$router.push(`/vendor/vendorMange/${command}`)
      }
    },
    confirmDownLoadExcel() {
      const loading = this.$loading({
        lock: true,
        text: '正在下载，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      this.$http
        .get(this.download.url, {
          params: {
            startTime: this.download.time ? this.download.time[0] : undefined,
            endTime: this.download.time ? this.download.time[1] : undefined,
            id: this.download.id
          }
        })
        .then(res => {
          this.$message.success('下载中，请到导出记录页面查看')
          this.download.show = false
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
        .finally(() => {
          loading.close()
        })
    },
    handleProductManage(command, val) {
      this.updateCurrentBusiness(val)

      if (command === 'standard') {
        this.$router.push(`/product/partnerProduct/normalProductlist/list`)
      } else if (command === 'partner') {
        this.$router.push(`/product/partnerProduct/list`)
      } else if (command === 'codeless') {
        this.$router.push(`/product/partnerProduct/codelessProduct/list`)
      } else if (command === 'seckill') {
        this.$router.push(`/product/partnerProduct/seckillProductList/list`)
      }
    },
    resetSearch() {
      this.searchValue = {
        businessId: '',
        searchByName: '',
        businessDistrictId: '',
        refundCheckScore: '',
        areaId: -1
      }
      this.getList()
    },
    // 请求后端获取商家列表
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1
      let params = {
        params: {
          page: this.currentPage,
          size: this.pageSize,
          ...this.searchValue
        }
      }
      this.$api.vendor
        .vendor('businessMixList', params)
        .then(res => {
          this.list = res.list.map(item => {
            let disabled = false
            if (item.areaIds.length > 1 && sessionStorage.currentAreaId != -1) {
              disabled = true
            }
            item.disabled = disabled
            return item
          })
          this.total = res.total
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    vendor(scope, isCheck = false) {
      const id = scope === '' ? '' : scope.row.id
      this.$router.push('/vendor/vendorMange/list/vendorEdit')
      this.$store.commit('vendor/params/updateParams', { key: 'vendorId', value: id })
      this.$store.commit('vendor/params/updateParams', { key: 'vendorCheck', value: isCheck })
    },
    businessCreateInfoExport() {
      // if (!this.vendorCreateExport.date || !this.vendorCreateExport.date.length) return
      this.vendorCreateExport.acting = true
      this.vendorCreateExport.fileUrl = ''
      let startTime = this.vendorCreateExport.date ? this.vendorCreateExport.date[0].getTime() : null
      let endTime = this.vendorCreateExport.date ? this.vendorCreateExport.date[1].getTime() : null
      this.$http
        .get('/boom-center-statistics-service/sysAdmin/exportExl/businessPublishExportExcel', {
          params: { startTime, endTime }
        })
        .then(res => {
          this.$message.success('下载中，请到导出记录页面查看')
          this.vendorCreateExport.show = false
          this.vendorCreateExport.acting = false
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
          this.vendorCreateExport.acting = false
        })
    }
  }
}
</script>

<style scoped lang="less">
.query {
  margin-top: 20px;
}
.product-info {
  display: flex;
  justify-content: center;
  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .title {
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.circle {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  color: white;
  font-size: 14px;
}
.circle-colorsuperA {
  background: #9fdf6a;
}
.circle-colorA {
  background: #a2cfbf;
}
.circle-colorB {
  background: #ec9ef3;
}
.circle-colorC {
  background: #f0b754;
}
.circle-colorD {
  background: #f7603e;
}
.circle-color {
  color: black;
}
.circle-color {
  color: black;
}
.circle-color- {
  color: black;
}
</style>
